// scroll animation
// デフォルトは要素の半分が見えたらアニメーション実行
// htmlで.prlx-viewにdata-viewを指定した数字分見えたらアニメーション実行
// data-once="1"で一回のみアニメーション
// ＊小数点で割合指定
prlx_init();
function prlx_init(){
  $(window).on('load', function(){
    parallax();
  });
  $(window).scroll(function(){
    parallax();
  });
}
function parallax(){
  var scroll = $(window).scrollTop();
  var wH = $(window).height();
  $('.prlx-view').each(function(){
    var dT = $(this).offset().top;
    var dH = $(this).innerHeight();
    var rate = $(this).attr('data-view');
    var once = $(this).attr('data-once');
    if(once == '1'){
      once = true;
    }else{
      once = false;
    }
    if(rate == undefined){
      rate = 0.5;
    }
    rate = new Number(rate);
    if(dT <= scroll + wH - dH*rate && scroll <= dT + dH - dH*rate){
      $(this).stop().addClass('-animated');
    }
    else if(scroll + wH < dT || dT + dH < scroll){
      if(!once){
        $(this).stop().removeClass('-animated');
      }
    }
  });
}