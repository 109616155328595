// common js

var windowWidth = screen.availWidth;
var windowSm = 960;

// if (windowWidth > windowSm) {

// } else {

// }

common();

// common
function common(){
  sliderMovie();
  youtubeThumb();
  movieModal();
  spHeaderLogo();
  spMenu();
  sliderNote();
  scrollSidebar();
  subnavFunc();
  headerType();
}


// sliderMovie
function sliderMovie(){
  $('.slider-movie').each(function(){
    $(this).slick({
      'dots': true,
      'arrows': false,
    });
  });
}

// youtube thumb set
function youtubeThumb(){
  $('.item-modal').each(function(){
    var movie = $(this).attr('data-movie');
    var y_id = movie.match(/[\/?=]([a-zA-Z0-9_\-]{11})[&\?]?/)[1];
    var img = '<img src="https://img.youtube.com/vi/'+y_id+'/maxresdefault.jpg" alt="">';
    $(this).append(img);
  });
}

// movieModal
function movieModal(){
  var scroll;
  var movie;
  var y_id;
  var bg = '<div class="screen-overlay"></div>';

  if(!$('.screen-overlay').length){
    $('.l-wrapper').append(bg);
  }

  $(document).on('click', '.item-modal', function(){
    movie = $(this).attr('data-movie');
    y_id = movie.match(/[\/?=]([a-zA-Z0-9_\-]{11})[&\?]?/)[1];
    $('.unit-modal').remove();
    var modalHtml = '\
      <div class="unit-modal">\
        <div class="unit-modal__close"><span></span></div>\
        <iframe src="https://www.youtube.com/embed/'+y_id+'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>\
      </div>';
    if(!$('.unit-modal').length){
      _open(modalHtml);
    }else{
      _close();
    }
  });
  $(document).on('click', '.unit-modal__close, .screen-overlay', function(){
    _close();
  });

  function _open(modalHtml){
    $('.l-wrapper').append(modalHtml);
    $('.screen-overlay').fadeIn(700);
    $('.unit-modal').fadeIn(700);
  }
  function _close(){
    $('.screen-overlay').fadeOut(700);
    $.when(
      $('.unit-modal').fadeOut(700).delay(700)
    ).done(function(){
      $('.unit-modal').eq(0).remove();
    });
  }
}

// spHeaderLogo
function spHeaderLogo(){
  var mv = $('.mainvisual');
  _resizeLogo();
  $(window).scroll(function(){
    _resizeLogo();
  });
  function _resizeLogo(){
    windowWidth = screen.availWidth;
    if (windowWidth <= windowSm) {
      if (mv.length) {
        if ($(window).scrollTop() > mv.innerHeight() / 2) {
          _small();
        }else{
          _default();
        }
      }else{
        _small();
      }
    }else{
      _default();
    }
    function _small(){
      $('.gnav__logo').css({
        'width': '90px',
        'height': '90px',
      });
    }
    function _default(){
      $('.gnav__logo').css({
        'width': '',
        'height': '',
      });
    }
  }
}

// spMenu
function spMenu(){
  $('.btn-menu').click(function(){
    if ($(this).hasClass('-active')) {
      $(this).removeClass('-active');
      $('.gnav__nav').fadeOut();
    }else{
      $(this).addClass('-active');
      $('.gnav__nav').fadeIn();
    }
  });
}


// sliderNote
function sliderNote(){
  $('.slider-note').each(function(){
    $(this).slick({
      'prevArrow': '<div class="slider-note__arrow -prev">',
      'nextArrow': '<div class="slider-note__arrow -next">',
    });
  });
}


// scrollSidebar
function scrollSidebar(){
  var lH = $('.l-header');
  var lF = $('.l-footer');
  _shift();
  $(window).on('scroll', function(){
    windowWidth = screen.availWidth;
    _shift();
  });
  function _shift(){
    if (windowWidth > windowSm) {
      var scroll = $(window).scrollTop();
      var distance = lF.offset().top - (scroll + lH.innerHeight());
      if (distance > 0) {
        distance = 0;
      }
      lH.css({
        'transform': 'translate(-'+window.scrollX+'px,'+distance+'px)',
      });
    }
  }
}


// subnavFunc
function subnavFunc(){
  $('.gnav__nav li>a').on({
    'mouseenter': function(){
      var nav = $(this).siblings('.gnav__nav__sub');
      if (nav.length) {
        nav.stop().fadeIn();
      }
    },
    'mouseleave': function(){
      var nav = $(this).siblings('.gnav__nav__sub');
      if (nav.length) {
        nav.stop().fadeOut();
      }
    }
  });
  $('.gnav__nav__sub').on({
    'mouseenter': function(){
      $(this).stop().fadeIn();
    },
    'mouseleave': function(){
      $(this).stop().fadeOut();
    }
  });
}


// headerType
function headerType(){
  var nav = $('.gnav');
  _class();
  $(window).on('resize', function(){
    _class();
  });
  function _class(){
    windowWidth = screen.availWidth;
    if (windowWidth > windowSm) {
      var wH = $(window).innerHeight();
      if (wH <= 790) {
        nav.addClass('-slim');
      }else{
        nav.removeClass('-slim');
      }
    }else{
      nav.removeClass('-slim');
    }
  }
}