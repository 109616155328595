// form js
contactForm();
function contactForm() {
  formFunc({
    target: 'contactForm',
    url: 'https://docs.google.com/forms/d/e/1FAIpQLScB9EJ6sWLoGP8s8jLMHQEWgF7mrnoj5h9k_sj8l6f3APW1dA/formResponse',
    array: [
      {
        name: 'entry.1176617421',
        label: 'お問い合わせの種類',
        rules: ['required'],
      },
      {
        name: 'entry.1884265043',
        label: '企業名・団体名（個人の場合はお名前）',
        rules: ['required'],
      },
      {
        name: 'entry.1439847198',
        label: 'お名前',
        rules: ['required'],
      },
      {
        name: 'entry.273134232',
        label: 'フリガナ',
        rules: ['required'],
      },
      {
        name: 'entry.1294755885',
        label: 'メールアドレス',
        rules: ['required'],
      },
      {
        name: 'entry.513669972',
        label: '電話番号',
        rules: [],
      },
      {
        name: 'entry.818269297',
        label: 'お問い合わせ内容',
        rules: ['required'],
      },
    ],
    redirect: '/contact/thanks/',
    cookie: false,
  });
}


function formFunc(setting){
  var target = setting.target;
  var redirect = setting.redirect;
  var array = setting.array;
  var url = setting.url;
  var cookie = setting.cookie;

  $('#'+target).submit(function (event) {
    event.preventDefault();
    $('.text-error').remove();
    
    var privacy = document[target].privacy.checked;

    var data = {};
    for (var i = 0; i < array.length; i++) {
      var name = array[i].name;
      data[name] = $('[name="'+name+'"]').val();
    }

    var err_required = [];
    var err_email = [];

    var valid = true;
    for (var i = 0; i < array.length; i++) {
      if(!_rules(array[i])){
        valid = false;
      }
    }

    _valid(err_required, 'err_required');
    _valid(err_email, 'err_email');

    if (privacy) {
      if (valid) {
        _submit(url,data,cookie);
      }else{
        alert('入力項目に不備があるか、未入力の項目があります。');
      }
    }else{
      alert('「個人情報の取扱い」を確認して項目にチェックを入れてください。');
    }




    // 以下 function

    function _rules(object){
      var flg = true;
      var name = object.name;
      var rules = object.rules;
      for (var i = 0; i < rules.length; i++) {
        if (rules[i] == 'required') {
          if (!_required(name)) {
            err_required.unshift(name);
            flg = false;
          }
        }
        if (rules[i] == 'email') {
          if (!_email(name)) {
            err_email.unshift(name);
            flg = false;
          }
        }
      }
      return flg;
    }

    function _required(name){
      var selector = $('[name="'+name+'"]');
      if (selector.val()) {
        return true;
      }else{
        return false;
      }
    }

    function _email(name){
      var selector = $('[name="'+name+'"]');
      var val = selector.val();
      if(val.match(/.+@.+\..+/)==null){
        return false;
      }else{
        return true;
      }
    }

    function _valid(err, type){
      for (var i = 0; i < err.length; i++) {
        var text = '';
        var selector = $('[name="'+err[i]+'"]');
        if (type == 'err_required') {
          text = '※必須項目です。';
        }
        if (type == 'err_email') {
          text = '※メールアドレスの形式で入力してください。';
        }
        selector.parent().append('<div class="text-error">'+text+'</div>');
      }
    }

    function _submit(url,data,cookie){
      $.ajax({
        url: url,
        data: data,
        type: "POST",
        dataType: "xml",
        statusCode: {
          0: function () {
            if (redirect) {
              _cookie(cookie);
              location.href= redirect;
            }
          },
          200: function () {
            if (redirect) {
              _cookie(cookie);
              location.href= redirect;
            }
          }
        }
      });
    }

    function _cookie(cookie){
      if(cookie){
        $.cookie('submit', 'success', { path: '/' , domain: 'https://www.hgg-japan.com/' });
      }
    }

  });
}